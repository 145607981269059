.supportai__privacypolicy {
  position: relative;
  font-family: var(--font-family);
}
.supportai__privacypolicy-navbar {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 6rem;
  background: none;
  width: 100%;
}
.supportai__privacypolicy-article {
  display: flex;
  flex-direction: column;
  position: relative;
}