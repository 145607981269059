.supportai__navbarstatic {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    position: fixed;
    color: black;
    align-items: center;
    padding: 2rem 6rem;
    background: none;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    transition: background-color 0.2s ease-in-out;
  }
  .supportai__navbarstatic-links {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  }
  .supportai__navbarstatic-links h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 22px;
    position: absolute;
    left: 0;
    top: 5px;
    margin-left: 2rem;
  }
  .supportai__navbarstatic-links_logo img {
    width: 100%;
    height: 60px;
  }
  .supportai__navbarstatic-links_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 4.5vh;
  }
  .darkmode {
    height: 100%;
  }
  .supportai__navbarstatic-links_container p,
  .supportai__navbarstatic-menu_container p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
  
    margin: 0 1rem;
    cursor: pointer;
  }
  .supportai__navbarstatic-menu {
    margin-left: 1rem;
  
    display: none;
    position: relative;
  }
  .supportai__navbarstatic-menu svg {
    cursor: pointer;
  }
  .supportai__navbarstatic-menu_container p {
    margin: 1rem 0;
  }
  .scroll-top {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 45px;
    margin: 1.5rem;
    cursor: pointer;
    border-radius: 2rem;
    box-shadow: 0 5px 36px rgba(0, 0, 0, 0.35);
  }
  @media screen and (max-width: 1050px) {
    .supportai__navbarstatic {
      position: relative;
      max-height: 10px;
    }
    .supportai__navbarstatic-links h1 {
      position: absolute;
      top: 0;
      left: 0;
    }
    .supportai__navbarstatic-links_container {
      display: none;
    }
    .supportai__navbarstatic-menu {
      display: flex;
      margin-top: 10rem;
    }
  }
  @media screen and (max-width: 700px) {
    .supportai__navbarstatic {
      padding: 2rem 4rem;
    }
  }
  @media screen and (max-width: 550px) {
    .supportai__navbarstatic {
      padding: 2rem;
    }
    .supportai__navbarstatic-menu_container {
      top: 20px;
    }
    .supportai__navbarstatic-menu_container-links-sign {
      display: block;
    }
  }