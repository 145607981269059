.supportai__videodemo {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}
.supportai__videodemo-header {
    margin-bottom: 2rem;
    text-align: center;
}
.supportai__videodemo-header h1 {
    font-size: 34px;
}
.supportai__videodemo-header p {
    color: var(--font-light);
    font-size: 16px;
    margin-top: 1rem;
}
.supportai__videodemo-container {
    width: auto;
}
.supportai__videodemo-container iframe {
    height: 580px;
    width: 1024px;
}