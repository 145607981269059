.portfolio__background {
  position: relative;
  z-index: 2;
}
.portfolio__background img {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.fade {
    position: absolute;
    height: 85vh;
    width: 100vw;
    border-bottom-left-radius: 10rem;
    box-shadow: 0 10px 36px rgba(0, 0, 0, 0.30);
}
.portfolio__header {
    display: flex;
    height: 95vh;
}
.portfolio__header-content {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.portfolio__header-content img {
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    height: 70%;
    margin-top: 12rem;
    margin-right: 8rem;
    object-fit: cover;
    -webkit-user-drag: none;
    user-select: none;
}
.portfolio__header-content__introduction {
    z-index: 2;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.portfolio__header-content__introduction h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    color: #fff;
    line-height: 65px;
}
.portfolio__header-content__introduction p {
    font-family: var(--font-family);
    font-size: 18px;
    margin-top: 2rem;
    color: #fff;
}
.portfolio__header-content__introduction a {
    z-index: 4;
    font-family: var(--font-family);
    background: #fff;
    color: var(--color-bg);
    padding: 0.75rem 1.5rem;
    margin: 2rem 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 2rem;
    transition: 0.5s;
}
.portfolio__header-content__introduction a:hover {
    box-shadow: 0 10px 36px rgba(0, 0, 0, 0.10);
    transition: 0.5s;
}
.portfolio__header-social {
    z-index: 2;
}
@media screen and (max-width: 1400px) {
    .portfolio__header-content h1 {
        font-size: 52px;
    }
    .portfolio__header-content p {
      font-size: 14px;
    }
}
@media screen and (max-width: 1250px) {
    .portfolio__header-content h1 {
        font-size: 42px;
        line-height: 55px;
    }
    .portfolio__header-content p {
      font-size: 12px;
    }
    .portfolio__header-content__introduction a {
      padding: 0.55rem 1rem;
      font-size: 12px;
    }
}
@media screen and (max-width: 1050px) {
    .portfolio__header-content {
        position: relative;
    }
    .portfolio__header-content img {
        height: auto;
        width: 0%;
        margin-top: 17rem;
    }
    .portfolio__header-content h1 {
        font-size: 42px;
        line-height: 55px;
    }
}




.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 5;
  }
  
  .popup__content {
    position: relative;
    background-color: #fff;
    padding: 30px;
    border-radius: 1rem;
    width: 550px;
  }
  
  .popup__content h2 {
    margin-bottom: 0.5rem;
  }
  .popup__content p {
    color: var(--font-light);
    margin-bottom: 1.5rem;
  }
  
  .popup__content label {
    display: block;
    margin-bottom: 5px;
  }
  
  .popup__content input[type='email'] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 2px solid black;
    font-size: 16px;
  }
  
  .checkbox {
    margin-bottom: 15px;
}
  
  .checkbox label {
    display: inline;
  }
  
  .checkbox input[type='checkbox'] {
    display: inline;
    margin-right: 5px;
}
  
  .popup__content button[type='submit'] {
    cursor: pointer;
    font-family: var(--font-family);
    background: #5121ff;
    color: white;
    padding: 0.5rem 1rem;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    margin-top: 0.8rem;
  }
  .popup__content .close {
    position: absolute;
    top: 23px;
    right: 0px;
    padding: 10px;
    line-height: 15px;
    cursor: pointer;
    font-size: 28px;
    font-weight: bold;
    color: rgb(36, 36, 36);
    border-radius: 2rem;
    margin-right: 1rem;
    transition: 0.2s ease-in-out;
  }
  .popup__content .close:hover {
    transition: 0.2s ease-in-out;
    background-color: #e0e0e0;
  }



.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 17px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .container input {
    display: inline;
    position: absolute;
    left: 0;
    height: 25px;
    width: 25px;
    margin-right: 5px;
  }
  .checkmark {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #e2e2e2;
  }
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  .container input:checked ~ .checkmark {
    background-color: #5121ff;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  .container .checkmark:after {
    left: 9px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .alert {
    z-index: 6;
    position: fixed;
    bottom: 0;    
    right: 0;
    margin: 2rem;
  }