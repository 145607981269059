@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

:root {
  --font-family: 'Poppins', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #007ED4 1.84%, #C482EE 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #007ED4 -13.86%, #C482EE 99.55%);
  
  --color-bg: #9275d9;
  --color-bg-dark: #252525;
  --color-white: #ffffff;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-dark: #252525;
  --font-light: #7c7c7c;
  --shadow: 0 10px 36px rgba(0, 0, 0, 0.30);
}