.fade-in {
    opacity: 0;
    transition: opacity 2s ease-in-out;
    transform: translateY(20px); /* Adjust the value as needed */
}    
.fade-in.visible {
    opacity: 1;
}
.supportai__about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
}
.supportai__about-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 55vh;
    margin-top: 15rem;
}
.supportai__about-info h1 {
    font-size: 34px;
}
.supportai__about-info p {
    color: var(--font-light);
    font-size: 16px;
    margin-top: 1rem;
}
.supportai__about-examples {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: rgb(243, 243, 243);
    width: 100vw;
    height: 135vh;
}
.supportai__about-examples h1 {
    font-size: 34px;
    margin-top: 5rem;
}
.supportai__about-examples p {
    color: var(--font-light);
    font-size: 16px;
    margin-top: 1rem;
}
.supportai__about-examples_cards {
    display: flex;
    flex-direction: row;
    margin-top: 8rem;
}
.supportai__about-examples_cards img {
    box-shadow: 0 10px 36px rgba(0, 0, 0, 0.20);
}
.supportai__about-faq {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 50vh;
    width: 60%;
    padding: 20px;
    margin-top: 5rem;
}
.supportai__about-faq h1 {
    font-size: 34px;
    margin-top: 5rem;
}
.supportai__about-faq p {
    color: var(--font-light);
    font-size: 16px;
    margin-top: 1rem;
}
.supportai__about-faq h1 {
    z-index: 1;
    text-align: center;
    margin-bottom: 4rem;
}
.supportai__about-faq ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 20px;
    list-style-type: none;
    padding: 0;
}
.supportai__about-faq ul li {
    margin-bottom: 20px;
}
.supportai__about-faq ul li h3 {
    font-size: 1.2em;
    font-weight: 300;
    text-align: left;
    margin-bottom: 10px;
}
.supportai__about-faq ul li p {
    font-size: 1em;
    text-align: left;
}
