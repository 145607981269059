.supportai__footer {
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: #1A1A1Aff;
    color: white;
    font-family: var(--font-family);
    height: 15vh;
    width: 100vw;
    align-items: center;
    margin-top: 15rem;
}
.supportai__footer-content {
    display: flex;
    text-align: center;
    align-items: center;
    margin-left: 5rem;
    width: 100%;
}
.supportai__footer-content h1 {
    font-size: 24px;
}
.supportai__footer-content p {
    margin-left: 3rem;
}
.supportai__footer-content_email {
    display: flex;
    position: absolute;
    right: 0;
}
.supportai__footer-content_email a {
    text-align: right;
    margin-right: 5rem;
}